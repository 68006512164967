/* eslint-disable canonical/filename-match-exported */
import ProjectQuery, {
  type ProjectPageQuery,
} from '@/__generated__/relay/ProjectPageQuery.graphql.js';
import { MissingResource } from '@/components/MissingResource/MissingResource.js';
import { PageHeadFragment } from '@/components/PageHead/PageHead.js';
import { TemplateRenderer } from '@/components/TemplateRenderer/TemplateRenderer.js';
import PROJECT_QUERY from '@/documents/queries/ProjectPage.js';
import { getPreloadedQuery } from '@/services/relay/network.js';
import { isPlaywrightTestEnvironment } from '@/utilities/isPlaywrightTestEnvironment.js';
import { normalizeUsername } from '@/utilities/normalizeUsername.js';
import { redirectInvalidUsername } from '@/utilities/redirectInvalidUsername.js';
import { resolveGraphQLResponse } from '@/utilities/resolveGraphQLResponse.js';
import { resolveMetadaUrl } from '@/utilities/resolveMetadataUrl.js';
import { resolveUsername } from '@/utilities/resolveUsername.js';
import { type GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import { type PreloadedQuery, usePreloadedQuery } from 'react-relay';

const PageProviders = dynamic(
  async () => await import('@/components/PageProviders/PageProviders.js'),
  {
    ssr: false,
  },
);

const Project = ({
  isCustomDomain,
  isPlaywright,
  queryRefs,
  ...props
}: {
  readonly isCustomDomain: boolean;
  readonly isPlaywright: boolean;
  readonly queryRefs: { query: PreloadedQuery<ProjectPageQuery> };
}) => {
  const queryRef = usePreloadedQuery<ProjectPageQuery>(
    PROJECT_QUERY,
    queryRefs.query,
  );

  if (!queryRef?.portfolioProjectBySlug || !queryRef?.userProfileByUsername)
    return <MissingResource />;

  const analyticsResourceData = {
    globalId: queryRef?.portfolioProjectBySlug.id,
    id: queryRef?.portfolioProjectBySlug.analyticsId,
    type: 'project' as const,
  };

  const { portfolioProjectBySlug } = queryRef;
  const templateProps = { ...props, queryRef: portfolioProjectBySlug };

  return (
    <PageProviders
      analyticsResourceData={analyticsResourceData}
      isPlaywright={isPlaywright}
      templateQueryRef={queryRef}
      userQueryRef={queryRef.userProfileByUsername}
      {...props}
    >
      <PageHeadFragment
        isCustomDomain={isCustomDomain}
        queryNode={queryRef}
      />
      <TemplateRenderer
        page="Project"
        {...templateProps}
      />
    </PageProviders>
  );
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const isPlaywright = isPlaywrightTestEnvironment(context);
  const { isCustomDomain, username } = await resolveUsername(context);
  if (!username) {
    return redirectInvalidUsername(context);
  }

  const pageUrl = resolveMetadaUrl(context, username);
  const pageQuery = await getPreloadedQuery(ProjectQuery, {
    slug: context.params?.['slug'],
    url: pageUrl,
    username,
  });

  const queryData = resolveGraphQLResponse(pageQuery.response);
  if (!queryData.data?.['portfolioProjectBySlug']) {
    return {
      notFound: true,
    };
  }

  const queryUsername =
    queryData.data?.['portfolioProjectBySlug']?.userProfile?.displayUsername;
  const normalizedUsername = normalizeUsername(queryUsername);

  if (normalizedUsername !== username) {
    // If this project does not belong to the user, return a 404
    return {
      notFound: true,
    };
  }

  return {
    props: {
      isCustomDomain,
      isPlaywright,
      preloadedQueries: {
        query: pageQuery,
      },
      username,
    },
  };
};

export default Project;
